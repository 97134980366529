<template>
  <v-tooltip left open-delay="500" class="theme-component">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-if="theme == THEME_LIGHT_KEY"
        icon
        v-bind="attrs"
        v-on="on"
        class="light--theme-icon"
        @click="changeTheme()"
      >
        <v-icon> mdi-moon-waning-crescent </v-icon>
      </v-btn>
      <v-btn
        v-if="theme == THEME_DARK_KEY"
        icon
        v-bind="attrs"
        v-on="on"
        @click="changeTheme()"
      >
        <v-icon> mdi-white-balance-sunny </v-icon>
      </v-btn>
    </template>
    <span>
      {{
        theme == THEME_DARK_KEY
          ? $localizationService.localize("theme.switch.to_light")
          : $localizationService.localize("theme.switch.to_dark")
      }}
    </span>
  </v-tooltip>
</template>

<script>
import { THEME, THEME_DARK_KEY, THEME_LIGHT_KEY } from "../app-theme";

export default {
  name: "ThemeSwitcher",

  data: () => ({
    theme: undefined,
    THEME_DARK_KEY,
    THEME_LIGHT_KEY,
  }),

  methods: {
    changeTheme() {
      THEME.switchTheme(this.theme);
    },
  },

  mounted() {
    this.theme = THEME.mainTheme;
  },
};
</script>

<style lang="scss">
.light--theme-icon {
  .v-icon {
    left: 4px !important;
  }
}
</style>
